import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// import { authApi } from '../__fakeApi__/authApi';
import * as authServices from 'services/auth';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const ACTIONS = {
  INITIALIZE: 'INITIALIZE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};

const handlers = {
  [ACTIONS.INITIALIZE]: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  [ACTIONS.LOGIN]: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  [ACTIONS.LOGOUT]: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          // const user = await authApi.me(accessToken);

          dispatch({
            type: ACTIONS.INITIALIZE,
            payload: {
              isAuthenticated: true,
              // user,
            },
          });
        } else {
          dispatch({
            type: ACTIONS.INITIALIZE,
            payload: {
              isAuthenticated: false,
              // user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ACTIONS.INITIALIZE,
          payload: {
            isAuthenticated: false,
            // user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    try {
      const response = await authServices.signIn({ email, password });

      const token = response.data.atk;
      localStorage.setItem('accessToken', token);

      dispatch({
        type: ACTIONS.LOGIN,
        payload: {
          isAuthenticated: true,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    dispatch({ type: ACTIONS.LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;

import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Speaker = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 2.99995C17.9999 2.82956 17.9563 2.66202 17.8733 2.51323C17.7902 2.36443 17.6706 2.23932 17.5256 2.14976C17.3806 2.0602 17.2152 2.00917 17.045 2.0015C16.8748 1.99383 16.7054 2.02979 16.553 2.10595L8.763 5.99995H5C4.20435 5.99995 3.44129 6.31603 2.87868 6.87863C2.31607 7.44124 2 8.20431 2 8.99995C2 9.7956 2.31607 10.5587 2.87868 11.1213C3.44129 11.6839 4.20435 12 5 12H5.28L7.051 17.316C7.11735 17.5152 7.24472 17.6884 7.41505 17.8112C7.58538 17.934 7.79004 18 8 18H9C9.26522 18 9.51957 17.8946 9.70711 17.7071C9.89464 17.5195 10 17.2652 10 17V12.618L16.553 15.894C16.7054 15.9701 16.8748 16.0061 17.045 15.9984C17.2152 15.9907 17.3806 15.9397 17.5256 15.8501C17.6706 15.7606 17.7902 15.6355 17.8733 15.4867C17.9563 15.3379 17.9999 15.1703 18 15V2.99995Z" />
  </svg>,
  'Speaker'
);

export default Speaker;

import numeral from 'numeral';

export const setupNumeral = () => {
  if (!numeral.locales.vi) {
    numeral.register('locale', 'vi', {
      delimiters: {
        thousands: '.',
      },
      currency: {
        symbol: 'đ',
      },
    });
    numeral.locale('vi');
  }
};

import axios from 'axios';
import { ROOT_API } from '../constants';

const axiosInstance = axios.create({
  baseURL: ROOT_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  config.headers.atk = token;
  return config;
});

export default axiosInstance;

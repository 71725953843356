import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  // Avatar,
  Box,
  // Button,
  // Divider,
  Drawer,
  // Link,
  // Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useMounted from "hooks/useMounted";
import ReceiptIcon from "@material-ui/icons/Receipt";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
// import useAuth from '../../hooks/useAuth';
import BriefcaseIcon from "../../icons/Briefcase";
// import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import FolderOpenIcon from '../../icons/FolderOpen';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
import SpeakerIcon from "../../icons/Speaker";
// import UserIcon from '../../icons/User';
import UsersIcon from "../../icons/Users";
// import Logo from '../Logo';
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";

import * as affiliateServices from "services/affiliate";

const sections = [
  {
    title: "Reports",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      // {
      //   title: 'Analytics',
      //   path: '/dashboard/analytics',
      //   icon: <ChartPieIcon fontSize="small" />,
      // },
      {
        title: "Finance",
        path: "/dashboard/finance",
        icon: <MonetizationOnIcon fontSize="small" />,
      },
      // {
      //   title: 'Account',
      //   path: '/dashboard/account',
      //   icon: <UserIcon fontSize="small" />,
      // },
    ],
  },
  {
    title: "Management",
    items: [
      {
        title: "Merchants",
        path: "/dashboard/merchants",
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: "Collections",
        path: "/dashboard/products",
        icon: <SpeakerIcon fontSize="small" />,
      },
      {
        title: "Transactions",
        icon: <PlaylistAddCheckIcon fontSize="small" />,
        path: "/dashboard/orders",
      },
      {
        title: "Invoices",
        path: "/dashboard/invoices",
        icon: <ReceiptIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const mounted = useMounted();
  // const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [affiliateList, setAffiliateList] = useState([]);

  const getAffiliateChannels = async () => {
    try {
      const response = await affiliateServices.getAllAffiliateChannels();

      if (mounted.current) {
        setAffiliateList(
          response.data.map((_) => ({
            id: _.id,
            name: _.name,
            iconUrl: _.iconUrl,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderSections = [
    ...sections,
    {
      title: "Affiliate channel",
      items: affiliateList.map((_) => ({
        title: _.name,
        path: `/dashboard/affiliate/${_.id}/detail`,
        icon: <BriefcaseIcon fontSize="small" />,
      })),
    },
  ];

  useEffect(() => {
    getAffiliateChannels();
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        {/* <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider /> */}
        <Box sx={{ p: 2 }}>
          {renderSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        {/* <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;

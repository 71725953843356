export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const ROOT_API = process.env.REACT_APP_API;
export const API_HOST = process.env.REACT_APP_API_HOST;

export const ZERO_TIMEZONE = "Etc/UTC";

export const BANK = {
  ACB: "ACB",
  AGRB: "Agribank",
  VCB: "Vietcombank",
  VP: "VP Bank",
  TP: "TP Bank",
  DONGA: "Dong A Bank",
  MRT: "Maritime Bank",
  SCB: "Sacombank",
  SEA: "Seabank",
  HSBC: "HSBC Viet Nam",
  BIDV: "BIDV",
  TCB: "Techcombank",
  NAMA: "Nam A Bank",
  HD: "HD Bank",
  MB: "MB Bank",
  VIB: "VIB",
  SGB: "Sai Gon Bank",
  SCBVN: "Standard Chartered Viet Nam",
  VTB: "Vietin Bank",
};

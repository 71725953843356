import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')``;

const Logo = (props) => (
  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 52 52"
    width="52"
    {...props}
  >
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5278:6424)">
        <rect
          width="32"
          height="32"
          rx="4"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4 24H9.6C8.71634 24 8 23.2041 8 22.2222V9.77778C8 8.79594 8.71634 8 9.6 8H22.4C23.2837 8 24 8.79594 24 9.77778V22.2222C24 23.2041 23.2837 24 22.4 24ZM11.5837 12.1706C11.818 11.9362 12.1979 11.9362 12.4322 12.1706L13.03 12.7683C14.6702 14.4085 17.3295 14.4085 18.9697 12.7683L19.5674 12.1706C19.8018 11.9362 20.1817 11.9362 20.416 12.1706C20.6503 12.4049 20.6503 12.7848 20.416 13.0191L19.8182 13.6169C17.7094 15.7257 14.2903 15.7257 12.1814 13.6169L11.5837 13.0191C11.3493 12.7848 11.3493 12.4049 11.5837 12.1706Z"
          fill="#F5544A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.6001 16.8004C33.6001 18.1255 33.09 19.1997 32.4608 19.1997L21.9394 19.1983C21.3102 19.1983 20.8001 18.1241 20.8001 16.799C20.8001 15.4739 21.3102 14.3997 21.9394 14.3997L32.4608 14.4012C33.09 14.4012 33.6001 15.4753 33.6001 16.8004Z"
          fill="#F5544A"
        />
      </g>
      <defs>
        <clipPath id="clip0_5278:6424">
          <rect
            width="32"
            height="32"
            rx="4"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </LogoRoot>
);

export default Logo;

import axios from "./axiosInstance";
import { ROOT_API } from "../constants";

const AFFILIATE_ROOT_API = `${ROOT_API}/affiliate-channels`;
const BRAND_AFFILIATE_ROOT_API = `${ROOT_API}/brand-affiliate-channels`;

export const getAllAffiliateChannels = () => axios.get(`${AFFILIATE_ROOT_API}`);

export const getAllAffiliateChannelsJoinCondition = () =>
  axios.get(`${AFFILIATE_ROOT_API}/join-conditions`);

export const getAffiliateChannelById = (data) =>
  axios.get(`${AFFILIATE_ROOT_API}/${data.id}`);

export const updateAffiliateChannelById = (data) =>
  axios.put(`${AFFILIATE_ROOT_API}/${data.id}`, { ...data.updateData });

export const getBrandAffiliateChannelsById = (data) =>
  axios.post(`${AFFILIATE_ROOT_API}/${data.id}/brand-affiliate-channels`, {
    ...data.requestData,
  });

export const updateBrandAffiliateChannelsById = (data) =>
  axios.put(`${BRAND_AFFILIATE_ROOT_API}/${data.id}`, { ...data.requestData });

export const deleteBrandAffiliateChannelsById = (data) =>
  axios.delete(`${BRAND_AFFILIATE_ROOT_API}/${data.id}`);

export const getCampaignsByAffiliateChannelId = (data) =>
  axios.post(`${AFFILIATE_ROOT_API}/${data.id}/campaigns/list`, {
    ...data.requestData,
  });

export const addCampaignByAffiliateChannelId = (affiliateId, data) =>
  axios.post(`${AFFILIATE_ROOT_API}/${affiliateId}/campaigns`, data);

export const getCampaignByCampaignId = (id) =>
  axios.get(`${AFFILIATE_ROOT_API}/campaigns/${id}`);

export const getCampaignBrandsOfCampaignByCampaignId = (id, data) =>
  axios.post(
    `${AFFILIATE_ROOT_API}/campaigns/${id}/campaign-brands/list`,
    data
  );

export const addCampaignBrandOfCampaignByCampaignId = (id, data) =>
  axios.post(`${AFFILIATE_ROOT_API}/campaigns/${id}/campaign-brands`, data);

export const removeCampaignBrandOfCampaignById = (id) =>
  axios.delete(`${AFFILIATE_ROOT_API}/campaigns/campaign-brands/${id}`);

export const changeCampaignByCampaignId = (id, data) =>
  axios.put(`${AFFILIATE_ROOT_API}/campaigns/${id}`, data);

export const startOrStopCampaignByCampaignId = (id, action) =>
  axios.post(`${AFFILIATE_ROOT_API}/campaigns/${id}/start-or-stop`, {
    action,
  });

import axios from "axios";
import { API_HOST } from "../constants";
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const InformationContext = createContext({});

export function InformationProvider({ children }) {
  const [bankList, setBankList] = useState([]);

  const bankById = useMemo(() => {
    const banks = {};

    bankList.forEach((bank) => {
      banks[bank.id] = bank;
    });

    return banks;
  }, [bankList]);

  useEffect(() => {
    axios.get(`${API_HOST}/bank-list.json`).then(({ status, data }) => {
      if (status === 200) {
        setBankList(data || []);
      }
    });
  }, []);

  return (
    <InformationContext.Provider value={{ bankList, bankById }}>
      {children || null}
    </InformationContext.Provider>
  );
}

InformationProvider.propTypes = {
  children: PropTypes.any,
};

export function useInformation() {
  return useContext(InformationContext);
}
